<template>
    <div class="d-flex align-items-center justify-content-end" v-bind="$attrs">

        <button v-if="canSaveActiveLayout"
            @click="doSaveLayout"
            :disabled="layoutManager.isSaving"
            :title="$t('Save active layout')"
            class="btn btn-primary btn-sm me-2">
            <div v-if="layoutManager.isSaving && !savingLayoutAsNew">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden"></span>
                </div>
                <span>{{$t('Saving...')}}</span>
            </div>
            <template v-else>
                {{$t('Save')}}
            </template>
        </button>
        <button v-if="canSaveAsNew" 
            class="btn btn-primary btn-sm me-2"
            :disabled="layoutManager.isSaving"
            :title="$t('Save as new layout')"
            @click="openSaveNew">
            <div v-if="layoutManager.isSaving && savingLayoutAsNew">
                <div class="spinner-border spinner-border-sm me-2" role="status">
                    <span class="visually-hidden"></span>
                </div>
                <span>{{$t('Saving...')}}</span>
            </div>
            <template v-else>
                {{$t('Save As')}}
            </template>
        </button>
    </div>

    <OModal ref="modalRef" :title="$t('Save layout as')" @hidden="onModalHidden">
        <div class="modal-body">
            <label>{{$t('Layout name')}}</label>
            <input v-model="layoutName"
                class="form-control form-control-sm mb-1">
            <label>{{$t('Layout description')}}</label>
            <OTextArea v-model="layoutDescription"
                class="form-control form-control-sm"
                style="max-height: 100px;"
                :rows="2" auto-grow no-resize/>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">{{$t('Close')}}</button>
            <button type="button" :disabled="!layoutName || layoutManager.isSaving" @click="doSaveNewLayout" class="btn btn-primary btn-sm">
                <div v-if="layoutManager.isSaving && savingLayoutAsNew">
                    <div class="spinner-border spinner-border-sm me-2" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                    <span>{{$t('Saving...')}}</span>
                </div>
                <template v-else>
                    {{$t('Save')}}
                </template>
            </button>
        </div>
    </OModal>
</template>

<script setup>
import { userSession } from 'o365.modules.configs.ts';
import { ref, computed, onMounted } from 'vue';
import OModal from 'o365.vue.components.Modal.vue';
import OTextArea from 'o365.vue.components.TextArea.vue';

const props = defineProps({
    dataObject: Object,
});

const emit = defineEmits(['saved']);

const modalRef = ref(null);

const layoutManager = computed(() => {
    return props.dataObject.layoutManager;
});

const activeLayoutObject = computed(() => {
    return props.dataObject.layoutManager?.activeLayoutObject;
});

const canSaveActiveLayout = computed(() => {
    return activeLayoutObject.value && activeLayoutObject.value.personId === userSession.personId;
});

const canSaveAsNew = computed(() => {
    return activeLayoutObject.value?.id && activeLayoutObject.value.contextId == null
});

const layoutName = computed({
    get() { return activeLayoutObject.value?.name; },
    set(value) {
        if (activeLayoutObject.value) {
            activeLayoutObject.value.name = value;
        }
    }
});
const layoutDescription = computed({
    get() { return activeLayoutObject.value?.description; },
    set(value) {
        if (activeLayoutObject.value) {
            activeLayoutObject.value.description = value;
        }
    }
});

const savingLayoutAsNew = ref(false);

function openSaveNew() {
    modalRef.value.show();
}

function onModalHidden() {
    activeLayoutObject.value._name.cancel();
    activeLayoutObject.value._description.cancel();
}

function asyncAlert(message, type='info', autohide = true) {
      import('o365.controls.alert.ts').then(({default: alert}) => {
        alert(message, type, {
            autohide: autohide,
            delay: 5000,
        });
    });
}

async function doSaveLayout() {
    const result = await layoutManager.value.saveLayout({
        includeFilter: true,
        includeSortBy: true,
        includeGroupGy: true
    }, true, false);
    if (result.success) {
        asyncAlert('Layout saved');
    } else {
        asyncAlert(result.errorMessage ?? 'Failed to save layout', 'danger', false);
    }
}

async function doSaveNewLayout() {
    savingLayoutAsNew.value = true;
    activeLayoutObject.value._name.commit();
    activeLayoutObject.value._description.commit();
    const result = await layoutManager.value.saveLayout(null, true, true);
    if (result.success) {
        asyncAlert('Layout saved');
    } else {
        asyncAlert(result.errorMessage ?? 'Failed to save layout', 'danger', false);
    }
    emit('saved');
    savingLayoutAsNew.value = false;
    modalRef.value.hide();
}


</script>